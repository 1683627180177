import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from '../api/axios'
import Coord from '../types/Coord'
import Organization from '../types/Organization'
import { resetOrder, setOrder } from './orderSlice'
import { RootState } from './store'
import { EOrderTarget } from '../lib/enum'
import { safeLocalStorage } from '../util/storage';

interface UserState {
  [key: string]: any
  id: number
  name: string
  email: string
  profileImage: string
  coord: Coord
  role: 'client' | 'client-public' | 'client-manager'
  organization: Organization
}

const initialState: UserState = {
  id: -1,
  name: '',
  email: '',
  profileImage: '',
  // coord: JSON.parse(localStorage.getItem('coord') ?? 'null') ?? {
  coord: JSON.parse(safeLocalStorage.getItem('coord') ?? 'null') ?? {
    latitude: 37.579391,
    longitude: 126.999036,
  },
  organization: {
    id: -1,
    type: '',
    name: '',
    licenseNumber: '',
    profileImage: '',
    email: '',
    phoneNumber: '',
    address: '',
    coordinate: { latitude: 37.579391, longitude: 126.999036 },
    point: '',
    certificate: '',
    affiliation: '',
    isVerified: false,
    createdAt: '',
    updatedAt: '',
    orderTarget: EOrderTarget.CLOSEST,
  },
  role: 'client',
}

export const loginAsync = createAsyncThunk(
  'user/loginAsync',
  async (credentials: { id: string; password: string }, _api) => {
    const { id, password } = credentials
    const response = await axios.post<{
      user: UserState
      accessToken: string
    }>('/auth/public-login', { id, password }, { withCredentials: true })
    console.log(response.data.user)
    axios.defaults.headers.common.authorization = `Bearer ${response.data.accessToken}`

    return response.data.user
  },
)

export const logoutAsync = createAsyncThunk('user/logoutAsync', async (_, api) => {
  const response = await axios.get('/auth/logout', { withCredentials: true })

  api.dispatch(resetOrder())
  api.dispatch(setOrder({ orders: [] }))

  axios.defaults.headers.common.authorization = false
  return response.status
})

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserState>) => {
      if (!['client', 'client-public', 'client-manager'].includes(action.payload.role)) return
      Object.keys(state).forEach(key => {
        if (action.payload[key]) state[key] = action.payload[key]
      })
    },
    setCoord: (state, action: PayloadAction<Coord>) => {
      state.coord = action.payload
    },
    resetUser: state => {
      state.id = initialState.id
      state.name = initialState.name
      state.email = initialState.email
      state.coord = initialState.coord
    },
  },
  extraReducers: builder => {
    builder
      .addCase(logoutAsync.pending, () => {})
      .addCase(logoutAsync.fulfilled, state => {
        Object.assign(state, initialState)
      })
      .addCase(logoutAsync.rejected, () => {})
      .addCase(loginAsync.fulfilled, (state, action) => {
        if (!['client', 'client-public', 'client-manager'].includes(action.payload.role)) return
        Object.keys(state).forEach(key => {
          if (action.payload[key]) state[key] = action.payload[key]
        })
      })
  },
})

export const { setUser, setCoord, resetUser } = userSlice.actions

export const selectCoord = (state: RootState) => state.user.organization?.coordinate
export const selectUser = (state: RootState) => state.user
export const selectIsLoggedIn = (state: RootState) => state.user.id > 0

export default userSlice
